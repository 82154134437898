import React from "react"
import SectionHeader from "../global/layout/SectionHeader"
import Carousel from "@elements/Carousel"
import { Swiper, SwiperSlide } from "swiper/react"
import SectionContainer from "../Container"
import Cta from "@global/cards/Cta"
import { formatChallengeDate } from "@helpers/dateHelper"

const HomepageChallenges = ({ challengesGroups, challenges }) => {
  const allChallenges = [...challenges.nodes, ...challengesGroups.nodes]
  allChallenges.sort((a, b) =>
    a.meta.firstPublishedAt < b.meta.firstPublishedAt ? 1 : -1
  )
  return (
    <SectionContainer padding={"top"}>
      <SectionHeader
        title="Challenges"
        buttonText="View all"
        buttonPath="/challenges"
        showButton={true}
      />
      <Carousel>
        <Swiper
          spaceBetween={12}
          slidesPerView="auto"
          initialSlide={1}
          centeredSlides={true}
          preventClicks={true}
          loop={true}
          className="swiper-container--3-col-for-medium"
        >
          {allChallenges.map(challenge => {
            const {
              anytimeChallenge,
              banner,
              originalId,
              path,
              startDate,
              title
            } = challenge
            return (
              <SwiperSlide
                className="swiper-slide--challenge"
                key={`slide-${challenge.id}`}
              >
                <Cta
                  heading={title}
                  isAnytime={anytimeChallenge}
                  subHeading={
                    anytimeChallenge
                      ? "Do Anytime"
                      : `Starts - ${formatChallengeDate(startDate)}`
                  }
                  startDate={startDate}
                  image={banner}
                  link={{ title, path }}
                  path={path}
                  id={originalId}
                  key={challenge.id}
                  registered={"isFav"}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Carousel>
    </SectionContainer>
  )
}

export default HomepageChallenges
