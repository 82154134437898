import React from "react"
import SectionContainer from "../Container"
import SectionHeader from "../global/layout/SectionHeader"
import CardsWrapper from "../global/cards/CardsWrapper"
import SimpleCard from "../global/cards/SimpleCard"

export const WorkoutSection = ({ data, allWorkoutsImage }) => {
  return (
    <SectionContainer padding="both" background="primary-tint">
      <SectionHeader
        title="Workouts"
        buttonText="View all"
        buttonPath="/workouts"
        showButton
      />
      <CardsWrapper orientation="horizontal" columns="4" size="large">
        <li>
          <SimpleCard
            type="workout"
            title="All Workouts"
            subHeading="View all"
            image={allWorkoutsImage}
            path="/workouts"
            elevated
          />
        </li>
        {data.map(item => {
          const { id, title, subHeading, image, path } = item
          return (
            <li key={id}>
              <SimpleCard
                type="workout"
                title={title}
                subHeading={subHeading}
                image={image}
                path={path}
                elevated
              />
            </li>
          )
        })}
      </CardsWrapper>
    </SectionContainer>
  )
}

export default WorkoutSection
