import React, { useEffect, useState } from "react"
import Layout from "@layouts/layout"
import { graphql } from "gatsby"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Banner from "@global/layout/Banner"
import Seo from "@elements/Seo"
import { useChallenge } from "../context/ChallengeContext/ChallengeState"
import * as Home from "../components/homepage"
import { useFavourite } from "../context/FavouritesContext/FavouriteState"
import Spinner from "@global/layout/Spinner"
import { navigate } from "@reach/router"

const Homepage = ({
  data: {
    categories,
    allWorkoutContent,
    newRecipes,
    content,
    global,
    deals,
    challenges,
    challengesGroups,
    latestVlogs,
    latestEatGreat,
    workouts,
    recipes,
    vlogs,
    dailyWorkouts,
    dailyWorkoutMiscContent
  },
  pageContext: { isHomepage, title }
}) => {
  const {
    hasActiveSubscriptions,
    isAuthenticated,
    user,
    region,
    favouriteWorkouts,
    favouriteRecipes,
    favouriteVlogs
  } = useAuth0()
  const [workoutObject, setWorkoutObject] = useState([])
  const [recipeObject, setRecipeObject] = useState([])
  const [vlogObject, setVlogObject] = useState([])
  const { getWorkoutsData, getRecipeData, getVlogData } = useFavourite()
  const { getUserContactLists, hasBeenChecked } = useChallenge()

  useEffect(() => {
    if (isAuthenticated && !hasBeenChecked) {
      getUserContactLists(user?.email)
    }
  }, [])

  useEffect(() => {
    parseWorkoutsIdIntoObject()
    parseRecipesIdIntoObject()
    parseVlogsIdIntoObject()
  }, [favouriteWorkouts, favouriteRecipes, favouriteVlogs])

  const parseWorkoutsIdIntoObject = () => {
    const getLocalworkouts = window.localStorage.getItem("workouts")
    const parseFavouritesWorkout = workouts.nodes.filter(x =>
      favouriteWorkouts.includes(x.originalId)
    )

    setWorkoutObject(parseFavouritesWorkout)
    getWorkoutsData(parseFavouritesWorkout)
  }

  const parseRecipesIdIntoObject = () => {
    const parseFavouritesRecipe = recipes.nodes.filter(x =>
      favouriteRecipes.includes(x.originalId)
    )
    setRecipeObject(parseFavouritesRecipe)
    getRecipeData(parseFavouritesRecipe)
  }

  const parseVlogsIdIntoObject = () => {
    const parseFavouritesVlogs = vlogs.nodes.filter(x =>
      favouriteVlogs.includes(x.originalId)
    )
    setVlogObject(parseFavouritesVlogs)
    getVlogData(parseFavouritesVlogs)
  }

  // if (loading || !user) {
  //   return <Spinner />
  // }

  return (
    <>
      <Layout region={region} isHomepage={true}>
        <Seo
          title={content.home.meta.title}
          description={content.home.meta.desc}
        />
        <Banner
          hiddenLarge={!global.newsBanner}
          image={global.newsBanner ? global.newsBanner : global.homeBanner}
          link={global.newsLink?.link}
        />
        <Home.WorkoutOfTheDay
          dailyWorkouts={dailyWorkouts.nodes[0]}
          heading={dailyWorkoutMiscContent.nodes[0].workoutOfTheDayHeading}
          leadText={dailyWorkoutMiscContent.nodes[0].workoutOfTheDayLeadText}
        />
        <Home.WorkoutSection
          data={categories.nodes}
          allWorkoutsImage={allWorkoutContent.media[0].image}
        />
        <Home.FeaturedFavourites
          workoutObject={workoutObject.slice(0, 3)}
          recipeObject={recipeObject.slice(0, 3)}
          vlogObject={vlogObject.slice(0, 3)}
          tabNames={["WORKOUTS", "RECIPES", "TIPS & TRICKS"]}
        />
        <Home.LatestRecipes data={newRecipes.nodes.slice(0, 6)} />
        <Home.HomepageChallenges
          challenges={challenges}
          challengesGroups={challengesGroups}
        />
        <Home.LatestVlogs vlogs={latestVlogs.nodes.slice(0, 3)} />
        <Home.EatGreat eatGreat={latestEatGreat.nodes.slice(0, 3)} />
        <Home.Deals content={content} deals={deals.nodes} global={global} />
      </Layout>
    </>
  )
}

export default Homepage

export const query = graphql`
  query HomeQuery($language: String, $locale: String) {
    categories: allDatoCmsWorkoutCategory(
      sort: { fields: position }
      limit: 7
    ) {
      nodes {
        id
        originalId
        title
        path
        subHeading: summary
        image: banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "278", h: "270" })
        }
      }
    }
    dailyWorkouts: allDatoCmsWorkoutOfTheDay(
      limit: 1
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        title
        originalId
        weekStart
        monday {
          ...WorkoutCard
        }
        tuesday {
          ...WorkoutCard
        }
        wednesday {
          ...WorkoutCard
        }
        thursday {
          ...WorkoutCard
        }
        friday {
          ...WorkoutCard
        }
        saturday {
          ...WorkoutCard
        }
        sunday {
          ...WorkoutCard
        }
      }
    }
    dailyWorkoutMiscContent: allDatoCmsMiscContent {
      nodes {
        workoutOfTheDayHeading
        workoutOfTheDayLeadText
      }
    }
    allWorkoutContent: datoCmsWorkout(path: { regex: "/speed-session/" }) {
      media {
        ... on DatoCmsWorkoutAudio {
          id
          originalId
          image: banner {
            gatsbyImageData(imgixParams: { fit: "crop", w: "278", h: "270" })
          }
        }
      }
    }
    global: datoCmsGlobal {
      homeBanner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "1280", h: "640" })
      }
      newsBanner {
        gatsbyImageData(imgixParams: { fit: "crop", w: "1280", h: "640" })
      }
      newsLink {
        ...LinkHelper
      }
      communityImage {
        gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
      }
    }
    newRecipes: allDatoCmsRecipe(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 6
    ) {
      nodes {
        ...RecipeCard
      }
    }

    workouts: allDatoCmsWorkout {
      nodes {
        ...WorkoutCard
      }
    }

    recipes: allDatoCmsRecipe {
      nodes {
        ...RecipeCard
      }
    }

    latestVlogs: allDatoCmsVlogPost(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 4
    ) {
      nodes {
        ...VlogCard
      }
    }
    vlogs: allDatoCmsVlogPost {
      nodes {
        ...VlogCard
      }
    }
    latestEatGreat: allDatoCmsEatGreat(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      limit: 4
    ) {
      nodes {
        ...EatGreatCard
      }
    }

    content: contentYaml(language: { eq: "en-NZ" }) {
      tabs {
        favouritesLabels {
          workouts
          recipes
          vlogs
        }
      }
      deals {
        caption
      }
      community {
        heading
        caption
      }

      home {
        meta {
          title
          desc
        }
      }
    }
    deals: allDatoCmsDeal(
      filter: { locale: { eq: $language }, country: { in: [$locale, "Both"] } }
    ) {
      nodes {
        title
        id
        originalId
        slug
        path
        code
        image {
          gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
        }
      }
    }
    challenges: allDatoCmsChallenge(
      filter: {
        locale: { eq: $language }
        group: { id: { eq: null } }
        country: { in: [$locale, "Both"] }
      }
    ) {
      nodes {
        id
        originalId
        slug
        title
        startDate
        date: startDate(formatString: "dddd do MMM")
        duration
        anytimeChallenge
        banner {
          gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
        }
        path
        internal {
          type
        }
        meta {
          firstPublishedAt
        }
      }
    }
    challengesGroups: allDatoCmsChallengeGroup(
      filter: { locale: { eq: $language } }
    ) {
      nodes {
        id
        originalId
        slug
        title
        startDate
        date: startDate(formatString: "dddd do MMM")
        duration
        anytimeChallenge
        banner {
          gatsbyImageData(imgixParams: { w: "770", h: "460", fit: "crop" })
        }
        path
        internal {
          type
        }
        meta {
          firstPublishedAt
        }
      }
    }
  }
`
