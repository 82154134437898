import React from "react"
import { graphql } from "gatsby"
import style from "./image.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ image, elevated = false, modifiers = [] }) => {
  if (!image || !image.gatsbyImageData) return null

  elevated && modifiers.push(style["image--elevated"])

  return (
    <div>
      <GatsbyImage
        className={[style.image, ...modifiers].join(" ")}
        image={image.gatsbyImageData}
      />
    </div>
  )
}

export default Image

export const query = graphql`
  fragment Image on DatoCmsImage {
    model {
      apiKey
    }
    id
    image {
      gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
    }
  }
`
