import React from "react"
import CardsContainer from "@global/cards/CardsContainer"
import { Swiper, SwiperSlide } from "swiper/react"
import { dealCtaConfig } from "@helpers/listHelper"
import Block from "@global/layout/Block"
import Image from "@elements/Image"
import Carousel from "@elements/Carousel"
import Cta from "@global/cards/Cta"
import Grid from "@elements/Grid"

const Deals = ({ deals, content, global }) => {
  return (
    <Block background="primary-tint">
      <Grid guttersForLarge>
        <Block padding="both">
          <CardsContainer
            lead={content.community.caption}
            heading={content.community.heading}
            orientation="horizontal"
            buttonPath="/dedikate-community"
            buttonText="More"
            modifier="full-width-image"
          >
            {[<Image image={global.communityImage} key="img-community" />]}
          </CardsContainer>
        </Block>
        {deals.length > 0 && (
          <Block padding="bottom" desktopPadding="both-double">
            <Carousel
              heading="deals"
              buttonPath={"/deals"}
              buttonText="View all"
              lead={content.deals.caption}
              modifier="1-col-for-large"
            >
              <Swiper
                spaceBetween={12}
                slidesPerView="auto"
                initialSlide={1}
                centeredSlides={true}
                preventClicks={true}
                loop={true}
                className="swiper-container--1-col-for-large"
              >
                {deals.map(deal => (
                  <SwiperSlide
                    className="swiper-slide--deal"
                    key={`deal-swiper-${deal.id}`}
                  >
                    <Cta {...dealCtaConfig(deal)} key={deal.id} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Carousel>
          </Block>
        )}
      </Grid>
    </Block>
  )
}

export default Deals
