import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import style from "./simpleCard.mod.scss"
import CardLinkWrapper from "./CardLinkWrapper"

const SimpleCard = props => {
  const { title, subHeading, image, elevated, path } = props

  const classes = [style[`simple-card`]]
  elevated && classes.push(style[`simple-card--elevated`])

  return (
    <CardLinkWrapper to={path} className={classes.join(" ")}>
      <div className={style[`simple-card__inner`]}>
        <div className={style[`simple-card__wrapper`]}>
          <GatsbyImage
            className={style[`simple-card__image`]}
            image={image.gatsbyImageData}
            alt=""
          />
        </div>
        <div className={style[`simple-card__content`]}>
          <h3 className={style[`simple-card__title`]}>{title}</h3>
          <h4 className={style[`simple-card__subHeading`]}>{subHeading}</h4>
        </div>
      </div>
    </CardLinkWrapper>
  )
}

export default SimpleCard
