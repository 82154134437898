import React from "react"
import SectionContainer from "../Container"
import SectionHeader from "@global/layout/SectionHeader"
import CardsWrapper from "@global/cards/CardsWrapper"
import FavouritesCard from "@global/cards/FavouritesCard"
import { format } from "date-fns"

const EatGreat = ({ eatGreat }) => {
  return (
    <>
      {eatGreat && (
        <SectionContainer padding="both">
          <SectionHeader
            title="DEDIKATE EAT GREAT"
            buttonPath={"/nutrition-chats/dedikate-eat-great"}
            buttonText="View all"
            showButton
          />
          <CardsWrapper orientation="horizontal" modifier="limit-3-on-large">
            {eatGreat.map(node => {
              const { originalId, path, chosenThumbnail, title, meta } = node
              return (
                <li key={originalId}>
                  <FavouritesCard
                    type="vlogs"
                    id={originalId}
                    title={title}
                    subHeading={format(
                      new Date(meta?.publishedAt),
                      "d MMMM yyyy"
                    )}
                    image={chosenThumbnail}
                    path={path}
                    showIcons={false}
                    showHeart={false}
                    hasImageApiKey={false}
                  />
                </li>
              )
            })}
          </CardsWrapper>
        </SectionContainer>
      )}
    </>
  )
}

export default EatGreat
