import React from "react"
import style from "./grid.mod.scss"
import PropTypes from "prop-types"

const Grid = ({
  heading = false,
  children,
  guttersForLarge = false,
  verticalAlign = false,
  config = [
    {
      size: "large",
      columns: "2"
    }
  ]
}) => {
  const bpConfig = config.map(
    ({ size, columns }) => style[`grid--${columns}-col-for-${size}`]
  )
  const modifiers = [style.grid, ...bpConfig]
  verticalAlign && modifiers.push(style[`grid--vertical-align`])
  guttersForLarge && modifiers.push(style[`grid--gutters-for-large`])

  return (
    <div className={modifiers.join(" ")}>
      {heading && <h2 className={style.grid__heading}>{heading}</h2>}
      <div className={style.grid__wrapper}>{children}</div>
    </div>
  )
}

export default Grid

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.array,
  heading: PropTypes.string
}
